html {
	overflow-x: hidden;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 800;
  line-height: 1.6;
  color: #292b2c;
  overflow-x: hidden;
}

a {
  transition: all 0.5s ease;
  text-decoration: none;
  color: #292b2c;
}

img, video {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #292b2c;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.67;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    span {
      display: inline-block;
      vertical-align: top;
      width: 90%;
    }
  }
}

.text-right {
  text-align: right;
}

.flex-col {
  flex-direction: column;
}

.flex-md-row {
  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.mb-7 {
  margin-bottom: 7rem !important;
}

.col-xxl-20 {
  @media (min-width: 1400px) {
    width: 20%;
  }
}

.swiper {
  width: 100%;
}